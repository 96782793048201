<template>
  <div />
</template>

<script>
import { XSollaWindowListener } from '@/services/checkout';
import { CHECKOUT_EVENTS_CONSTANTS } from '@/domain/checkout';
import { URL_CONSTANTS } from '@/externalURLs';

export default {
    props: {
        token: {
            type: String,
            required: true,
        },
        eventProperties: {
            type: Object,
            required: true,
        },
    },
    mounted() {
        this.XPayInitListener();
        this.initializeXPayWidget();
        XSollaWindowListener.initialize(this.eventProperties);
    },
    methods: {
        XPayInitListener() {
            window.XPayStationWidget.on(CHECKOUT_EVENTS_CONSTANTS.INIT, function() {
                window.XPayStationWidget.open();
            });
        },
        initializeXPayWidget() {
            window.XPayStationWidget.init({
                access_token: this.token,
                sandbox: process.env.VUE_APP_ENVIRONMENT !== 'production',
                lightbox: {
                    spinner: 'custom',
                    width: '740px',
                    spinnerUrl: URL_CONSTANTS.VOICEMOD_LOADING_GIF,
                    modal: true,
                    closeByClick: false,
                    closeByKeyboard: false,
                },
            });
        },
    },
};
</script>
