<template>
  <div class="sticker-with-text">
    <figure
      data-testid="sticker-image"
      class="sticker-with-text__image"
    >
      <slot name="sticker" />
    </figure>
    <div class="sticker-with-text__right">
      <div
        data-testid="sticker-title"
        class="sticker-with-text__title"
      >
        {{ title1 }}
        <div class="sticker-with-text__title2">
          {{ title2 }}
        </div>
      </div>
      <p data-testid="sticker-text">
        <slot name="text" />
      </p>
    </div>
  </div>
</template>

<script>
export default {
    props: {
        title1: {
            type: String,
            default: '',
        },
        title2: {
            type: String,
            default: '',
        },
    },
};
</script>

<style src="./StickerWithTextComponent.scss" lang="scss"></style>
