const CHECKOUT_EVENTS_CONSTANTS = {
    INIT: 'init',
    OPEN: 'open',
    OPEN_WINDOW: 'open-window',
    OPEN_LIGHTBOX: 'open-lightbox',
    LOAD: 'load',
    CLOSE: 'close',
    CLOSE_WINDOW: 'close-window',
    CLOSE_LIGHTBOX: 'close-lightbox',
    STATUS: 'status',
    STATUS_INVOICE: 'status-invoice',
    STATUS_DELIVERING: 'status-delivering',
    STATUS_TROUBLED: 'status-troubled',
    STATUS_DONE: 'status-done',
};

export {
    CHECKOUT_EVENTS_CONSTANTS,
};
